import { render, staticRenderFns } from "./contacts.vue?vue&type=template&id=260c81be&"
import script from "./contacts.vue?vue&type=script&lang=js&"
export * from "./contacts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MapMain: require('/home/d/didisher2/ac-avangard.rf/www/components/map/map-main.vue').default,ContactsSlider: require('/home/d/didisher2/ac-avangard.rf/www/components/contacts/contacts-slider.vue').default})
